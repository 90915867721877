import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="preregister"
export default class extends Controller {
  static targets = ["dob", "amountDisabled", "amountHidden"];
  static values = {
    fee: Number,
    feeOnline: Number,
  };

  connect() {
    let online = document.querySelector(
      'input[name="preregistration[online]"]:checked'
    );
    this.populateAmount(online.value);
  }

  setFee(e) {
    this.populateAmount(e.target.value);
  }

  populateAmount(online_value) {
    let member_type_cd = document.querySelector(
      'input[name="preregistration[member_type_cd]"]:checked'
    );
    this.amount = online_value === "true" ? this.feeOnlineValue : this.feeValue;

    if (member_type_cd !== null) this.checkOver70(+member_type_cd.value);
  }

  handleClick(e) {
    let member_code = +e.target.value;
    // uncheck all member_type_cd
    document
      .querySelectorAll('input[name="preregistration[position_type_cd]"]')
      .forEach((el) => {
        el.checked = false;
      });
    this.checkOver70(member_code);
  }

  checkOver70(member_code) {
    if (member_code === 3) {
      this.dobTarget.classList.remove("hidden");
      this.amountDisabledTarget.value = "0";
      this.amountHiddenTarget.value = "0";
    } else {
      this.dobTarget.classList.add("hidden");
      this.amountDisabledTarget.value = this.amount;
      this.amountHiddenTarget.value = this.amount;
    }
  }

  handleClickPositionType(e) {
    let position_type_cd = +e.target.value;
    const member_type_cd = +document.querySelector(
      'input[name="preregistration[member_type_cd]"]:checked'
    ).value;
    if (member_type_cd === 3) {
      this.amountDisabledTarget.value = "0";
      this.amountHiddenTarget.value = "0";
    } else {
      this.amountDisabledTarget.value = this.amount;
      this.amountHiddenTarget.value = this.amount;
    }
    if (position_type_cd === 3) {
      document.getElementById(
        "preregistration_member_type_cd_2"
      ).checked = true;
      this.amountDisabledTarget.value = "0";
      this.amountHiddenTarget.value = "0";
    }
  }
}
