// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers";
import "./src/jquery";
import "trix";
import "@rails/actiontext";
import "@fortawesome/fontawesome-free/js/all";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

document.addEventListener("turbo:load", () => {
  // console.log("Hello from Turbo!");
});
